@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "Inter-Black";
  src: url("../fonts/Inter_24pt-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Light";
  src: url("../fonts/Inter_24pt-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Regular";
  src: url("../fonts/Inter_24pt-Regular.ttf") format("truetype");
}

body {
  font-family: "Inter-Regular", sans-serif;
}

/* Add font family */
.inter {
  font-family: "Inter-Regular", sans-serif;
}

.inter-black {
  font-family: "Inter-Black", sans-serif;
}

.inter-light {
  font-family: "Inter-Light", sans-serif;
}

.undraggable {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

/* styles.css */
/* Custom Scroll Bar for WebKit browsers */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #2b2b2b;
}

::-webkit-scrollbar-thumb {
  background: #3b3b3b;
}

::-webkit-scrollbar-thumb:hover {
  background: #555555;
}

/* Scroll bar styling for other browsers */
body {
  scrollbar-width: none;
  /* Firefox */
  scrollbar-color: #2b2b2b #04040400;
  /* Firefox */
  background-color: #080A0B;
  color: rgb(235, 235, 235);
  position: relative;
  overflow-x: hidden;
}

html {
  scrollbar-width: thin;
  position: relative;
  overflow-x: hidden;
}

.background-circle::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 1000px;
  height: 1000px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 1));
  transform: translateX(-50%);
  z-index: -1;
  opacity: 0.6;
}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(-10%);
    animation-timing-function: cubic-bezier(0.32, 0.12, 0.17, 1);
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0.3, 0.29, 0.64, 0.91);
  }
}

.animate-bounce {
  animation: bounce 7s infinite;
}